/* CSS Document */
.noos-select {
  position: relative;
  cursor: pointer;
  min-width: 100px; }

.noos-select select {
  display: none; }

.noos-select.mobile select {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 5; }

.noos-select .l {
  position: absolute;
  top: 100%;
  z-index: 99;
  -webkit-transform: translateY(-50%) scaleY(0);
  -ms-transform: translateY(-50%) scaleY(0);
      transform: translateY(-50%) scaleY(0);
  -webkit-transition: all 0.3s linear 0.15s;
  transition: all 0.3s linear 0.15s; }

.noos-select.show .l {
  -webkit-transform: translateY(0) scaleY(1);
  -ms-transform: translateY(0) scaleY(1);
      transform: translateY(0) scaleY(1);
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s; }

.noos-select .l > .a {
  display: block;
  position: absolute;
  right: 27px;
  top: 0;
  background: #fff;
  width: 12px;
  height: 12px;
  -wevkit-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: top 0.15s linear 0s;
  transition: top 0.15s linear 0s; }

.noos-select.show .l > .a {
  top: -5px;
  -webkit-transition: top 0.15s linear 0.3s;
  transition: top 0.15s linear 0.3s; }

.noos-select .l ul,
.noos-select .l ul li {
  padding: 0;
  background: none;
  margin: 0;
  position: relative; }

.noos-select .l ul li {
  position: relative; }

.noos-select .v {
  margin-right: 15px; }

.noos-select > .a {
  position: absolute;
  width: 15px;
  right: 0;
  top: 0;
  height: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.noos-select > .a:before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -3px;
  border-left: solid 3px #000;
  border-bottom: solid 3px #000;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
      transform: rotate(-45deg); }

.noos-select.show > .a {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg); }
